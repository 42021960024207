<template>
  <div>
    <p v-b-toggle.societe-fonds-commerce-sidebar class="gavc-legende gavc-text-tory">
      <i class="gavc-icon gavc-icon-bulb"></i> Qu'est ce qu'un fonds de commerce ?
    </p>
    <b-sidebar id="societe-fonds-commerce-sidebar"  right shadow backdrop>
      <div class="px-3 py-2">
        <p class="gavc-h3 gavc-text-tory">
          Fonds de commerce
        </p>
        <p class="gavc-p">
          <strong>Le fonds de commerce</strong> est "l'ensemble des éléments corporels et incorporels affectés à
          l'exploitation d'une activité commerciale ou industrielle".
        </p>
        <p class="gavc-p">
          <strong>Les éléments corporels</strong> = le mobilier, le matériel, l'outillage, les agencements et
          installations, la marchandise
        </p>
        <p class="gavc-p">
          <strong>Les éléments incorporels</strong> = La clientèle, l’enseigne, les brevets, les compétences, les
          logiciels, les marques.
        </p>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: 'helpingSocieteFondsCommerceSidebar',
}
</script>
